import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../../layouts"
import Container from "../../components/container"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Hero from "../../components/hero"
import Footer from "../../components/footer"
import GreenhouseForm from "../../components/greenhouse-form"

import { getLayout } from "../../utils/layout"
import { htmlDecode } from "../../utils/html-decode"

// display information sourced from gatsby-source-greenhouse-job-board
const JobPosting = ({ location, data }) => {
  const [htmlContent, setHtmlContent] = useState(``)
  useEffect(() => {
    setHtmlContent(htmlDecode(data.greenhouseJob.content))
  }, [data.greenhouseJob.content])
  const layout = getLayout(location.pathname)
  // match the shape of the expected page props since the data for this page isn't from Contentful
  const page = {
    name: data.greenhouseJob.title,
    title: `Now Hiring: ${data.greenhouseJob.title}`,
    parentPage: { name: "Careers", slug: "careers", title: "Careers" },
  }

  return (
    <Layout pathname={location.pathname} background={layout.background}>
      <SEO contentfulPage={page} url={location.href} />
      <Header />
      <Hero layout={layout} page={page} />
      <Container>
        <div
          css={theme => ({ marginTop: theme.space[10], maxWidth: `39rem` })}
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
        <div css={theme => ({ marginTop: theme.space[10], maxWidth: `39rem` })}>
          <GreenhouseForm
            jobId={data.greenhouseJob.gh_Id}
            questions={data.greenhouseJob.questions}
          />
        </div>
      </Container>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query GreenhouseJob($id: String!) {
    greenhouseJob(id: { eq: $id }) {
      id
      gh_Id
      title
      content
      questions {
        label
        description
        required
        fields {
          name
          type
          values {
            label
            value
          }
        }
      }
    }
  }
`

export default JobPosting
